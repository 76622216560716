const gwNameToId = {
    "1_Testy_vpn_rak": "ac1f09fffe0cd4ba",
    "3_Rezerwa_Fila_102": "ac1f09fffe091e4b",
    "3_Rezerwa_Fila_103": "ac1f09fffe091ec5",
    "3_Rezerwa_Fila_wew_51": "ac1f09fffe0ddbf9",
    "3_Rezerwa_Fila_wew_69": "ac1f09fffe10a771",
    "3_Rezerwa_Fila_wew_72": "ac1f09fffe0dd42b",
    "3_Rezerwa_Fila_wew_73": "ac1f09fffe10a783",
    "Amratech": "ac1f09fffe0dede3",
    "Bochnia_Brzeznicka_19_kl2_FILA": "ac1f09fffe091e99",
    "Bochnia_Kazimierza_Wielkiego_38_FILA": "ac1f09fffe091ed1",
    "Bochnia_Kolejowa_13_kl2_FILA_brak_pradu": "ac1f09fffe091e79",
    "Bornesulinowo-Niepodleglosci5_FILA": "ac1f09fffe059058",
    "BrzegDolny_Bema2_FILA": "ac1f09fffe091f07",
    "BrzegDolny_Lubiaz_Wysokiego7_FILA": "ac1f09fffe091cc7",
    "BrzegDolny_Teczowa4_FILA": "ac1f09fffe091e6d",
    "BrzegDolny_ZGMadministrator_2wew_Wyspianskiego3_4_FILA": "ac1f09fffe05905e",
    "BrzegDolny_ZGMadministrator_3wew_Wyszynskiego3_4_Wilcza11_FILA": "ac1f09fffe059066",
    "Brzeszcze_Daszynskiego_26_FILA": "ac1f09fffe091e69",
    "Brzeszcze_Nowa_Kolonia_4_FILA": "ac1f09fffe091e6b",
    "Brzeszcze_Slowackiego_7_FILA": "ac1f09fffe091e87",
    "Brzeszcze_Szymanowskiego_2_FILA": "ac1f09fffe091ee5",
    "Brzeziny_Moniuszki14-16": "ac1f09fffe014c05",
    "Brzeziny_Zespol_Szkol": "ac1f09fffe014bf5",
    "Bydgoszcz_Wyszynskiego_34": "ac1f09fffe1089a1",
    "Bystra_OSP": "ac1f09fffe091e9b",
    "BystrzycaKlodzka": "ac1f09fffe091e75",
    "Bytom_ZBM_Chrzanowskiego1B_FILA": "ac1f09fffe052055",
    "Bytom_ZBM_Falata30_FILA": "ac1f09fffe091e63",
    "Bytom_ZBM_Koscielna10B_FILA": "ac1f09fffe014c09",
    "Bytom_ZBM_OrlatLwowskich1B_FILA": "ac1f09fffe013d81",
    "Bytom_ZGM_Karpacka8b_wew_FILA": "ac1f09fffe091cb7",
    "Bytom_ZGM_KrolowejJadwigi9_FILA": "ac1f09fffe051f29",
    "Bytom_ZGM_Krupinskiego_11A_FILA": "ac1f09fffe109da1",
    "Bytom_ZGM_Moniuszki32_FILA": "ac1f09fffe0cd528",
    "Bytom_ZGM_Reja8_FILA": "ac1f09fffe091e4d",
    "Bytom_ZGM_Reja8_FILA_do_odeslania": "ac1f09fffe051eee",
    "Bytom_ZGM_Tarnogorska6_FILA": "ac1f09fffe091e9f",
    "Bytow_Gdanska_49A": "ac1f09fffe0dec2b",
    "Bytow_Gdanska_49A_do_odeslania": "ac1f09fffe0dedd7",
    "Bytow_Gdanska_49B": "ac1f09fffe108f09",
    "Bytow_Gdanska_49C": "ac1f09fffe10a68b",
    "Chojnice_WM_Lesna_wew": "ac1f09fffe091d03",
    "Chojnow_Rokitki_Szkola_FILA": "ac1f09fffe091e61",
    "Czernin": "ac1f09fffe004f28",
    "Czestochowa_Kazimierza5A": "ac1f09fffe091cc9",
    "DabrowaGornicza_Manhattan_Chemiczna3_FILA": "ac1f09fffe0debef",
    "DabrowaGornicza_Manhattan_Pilsudskiego22_FILA": "ac1f09fffe091e65",
    "DabrowaGornicza_Manhattan_Pilsudskiego_85_AS_FILA": "ac1f09fffe091ecd",
    "DabrowaGornicza_Partner_Adamieckiego8_kl7": "ac1f09fffe091e5d",
    "DabrowaGornicza_Partner_Kasprzaka22": "ac1f09fffe0cd51e",
    "DabrowaGornicza_Partner_Sosnowiec_Pawia4": "ac1f09fffe091ea5",
    "DabrowaGornicza_Storczykow51_klJ_FILA": "ac1f09fffe0dda3f",
    "DabrowaTarnowska_Jagiellonska2": "ac1f09fffe091ed3",
    "DabrowaTarnowska_Kosciuszki4": "ac1f09fffe0dda59",
    "DabrowaTarnowska_Wyszynskiego1": "ac1f09fffe091ee7",
    "DabrowaTarnowska_Zabno_Siedemsetlecia3": "ac1f09fffe0dec33",
    "Darlowo_Belg_Kerlink": "7276ff000b03101c",
    "Darlowo_Kosciol_Nowa": "ac1f09fffe013d83",
    "Darlowo_Wieza_Rak": "ac1f09fffe014bfc",
    "Dwikozy_ZbiornikWodny": "ac1f09fffe051f34",
    "Elblag_Aleja_Grunwaldzka_100_106_kl102_FILA_do_montazu": "ac1f09fffe059064",
    "Elblag_Jutrzenka_Legionow34_FILA": "ac1f09fffe091e49",
    "Elblag_Jutrzenka_Sienkiewicza2_FILA": "ac1f09fffe091eb9",
    "Elblag_Krolewiecka46_48_FILA": "ac1f09fffe0dd3dd",
    "Elk_Dabrowskiego10_AS": "ac1f09fffe091e4f",
    "Elk_Kilinskiego3F": "ac1f09fffe014c08",
    "Elk_Mickiewicza": "60c5a8fffe76f8bd",
    "Elk_Wojska_Polskiego_Nowa": "ac1f09fffe091ea9",
    "FILA_4p_TestInstalacji": "ac1f09fffe013d80",
    "Fila_BiR_Rak7258": "ac1f09fffe051676",
    "Fila_BiR_legalizacja": "ac1f09fffe05205b",
    "Gdansk_ABC_Derdowskiego25_AS_FILA": "ac1f09fffe0ddc91",
    "Gdansk_ABC_Drzymaly5_AS_FILA": "ac1f09fffe091ce5",
    "Gdansk_ABC_Obroncow_Westerplatte_34_AS_FILA": "ac1f09fffe091cfb",
    "Gdansk_ABC_Podhalanska10_AS_FILA": "ac1f09fffe091d05",
    "Gdansk_ABC_WP25_AS_FILA": "ac1f09fffe091cd5",
    "Gdansk_BIZAN_Luzycka28_FILA": "ac1f09fffe014bfb",
    "Gdansk_EUROFORMAT_Abrahama15_wew": "ac1f09fffe0ddab1",
    "Gdansk_EUROFORMAT_Slowackiego55B": "ac1f09fffe091ef5",
    "Gdansk_Energetyk_Zaspa33d_wew": "ac1f09fffe091cbd",
    "Gdansk_HOMESYSTEM_Beniowskiego_3a_FILA": "ac1f09fffe091eeb",
    "Gdansk_HOMESYSTEM_Ketrzynskiego24_FILA": "ac1f09fffe052053",
    "Gdansk_HOMESYSTEM_Obroncow_Wybrzeza15": "ac1f09fffe104c04",
    "Gdansk_HOMESYSTEM_Obroncow_Wybrzeza17": "ac1f09fffe051efb",
    "Gdansk_HOMESYSTEM_Obroncow_Wybrzeza18_wew": "ac1f09fffe0dda67",
    "Gdansk_HOMESYSTEM_Obroncow_Wybrzeza_wew2": "ac1f09fffe091cdf",
    "Gdansk_HOMESYSTEM_Opacka20_FILA": "ac1f09fffe091e89",
    "Gdansk_HOMESYSTEM_Wika_Czarnowskiego_2_FILA": "ac1f09fffe091eb7",
    "Gdansk_POLUDNIE_Dyw_Wolynskiej16B": "ac1f09fffe051f1c",
    "Gdansk_POLUDNIE_Okulickiego1E_AS": "ac1f09fffe051f21",
    "Gdansk_PON_Wrobla_18_wew": "ac1f09fffe091cf7",
    "Gdansk_PON_Wyspianskiego_22": "ac1f09fffe091cef",
    "Gdansk_Subislawa_27C": "ac1f09fffe0ddc8d",
    "Gdansk_Zielony_Stok_35_FILA": "ac1f09fffe091e7b",
    "Gilowice_Urzad_Gminy": "ac1f09fffe091ee9",
    "Gliwice_Donata_Zygmuntowska26": "ac1f09fffe0cd51a",
    "Gliwice_Donata_Zygmuntowska80": "ac1f09fffe091eed",
    "Gliwice_SMOP_ObroncowPokoju18": "ac1f09fffe0cd4fa",
    "Gliwice_SMOP_ObroncowPokoju7": "ac1f09fffe0cd47e",
    "Gliwice_SMOP_Paderewskiego104": "ac1f09fffe0cd498",
    "Gliwice_ZBM_Chodkiewicza27_FILA": "ac1f09fffe091cb9",
    "Gliwice_ZBM_Granitowa17-19_FILA": "ac1f09fffe05204f",
    "Gliwice_ZBM_Kochanowskiego33C_FILA": "ac1f09fffe091ebf",
    "Gliwice_ZBM_Kozielska9_FILA": "ac1f09fffe091e57",
    "Gliwice_ZBM_Miodowa71_AS_brak_pradu_FILA": "ac1f09fffe091ccd",
    "Gliwice_ZBM_Mlynska2_FILA": "ac1f09fffe091e77",
    "Gliwice_ZBM_Perkoza9_FILA": "ac1f09fffe051f44",
    "Gliwice_ZBM_Plac_Jasminu6_FILA_brak_pradu": "ac1f09fffe0dda2f",
    "Gliwice_ZBM_Pszczynska112_AS_FILA": "ac1f09fffe051f2c",
    "Gliwice_ZBM_Rolnikow217_FILA": "ac1f09fffe091cf1",
    "Gliwice_ZBM_Rydygiera_7_AS_FILA": "ac1f09fffe091f0f",
    "Gliwice_ZBM_Slowackiego36_FILA": "ac1f09fffe091e6f",
    "Gliwice_ZBM_Wojtowska21_FILA": "ac1f09fffe091cdb",
    "Gliwice_ZBM_Ziemowita1_AS_FILA": "ac1f09fffe051f47",
    "Gliwice_ZBM_Zytnia135_FILA": "ac1f09fffe091cfd",
    "GryfinoSMDO_Szczecin_Kruszcowa4_do_odeslania": "ac1f09fffe10a751",
    "Gryfino_CZWM_Krasinskiego85": "ac1f09fffe014bef",
    "Gryfino_SMDO_Luzycka119": "ac1f09fffe051f02",
    "Gryfino_SMDO_Luzycka65_do_odeslania": "ac1f09fffe05203b",
    "Gryfino_SMDO_NoweCzarnowo51": "ac1f09fffe05905c",
    "Gryfino_SMDO_NoweCzarnowo55_nowa_do_odeslania": "ac1f09fffe091ccb",
    "Gryfino_SMDO_Slowianska5": "ac1f09fffe014c01",
    "Jaslo_Ducala_9": "ac1f09fffe059056",
    "Jaslo_Dworcowa_1": "ac1f09fffe059050",
    "Jaslo_Florianska32": "ac1f09fffe059054",
    "Jaslo_Krasinskiego_12": "ac1f09fffe05906a",
    "Jaslo_Mickiewicza13": "ac1f09fffe05904c",
    "Jaslo_Nowa17": "ac1f09fffe059072",
    "Jurata_Miedzymorze_10_FILA": "ac1f09fffe0ddc99",
    "Jurata_Miedzymorze_10_FILA_do_odeslania": "ac1f09fffe053a26",
    "Ketrzyn_Kwiatowa2_FILA_do_montazu": "ac1f09fffe108e3d",
    "Ketrzyn_Kwiatowa2_FILA_do_odeslania": "ac1f09fffe091ce3",
    "Ketrzyn_Westerplatte_24_FILA": "ac1f09fffe091ef9",
    "Kisielice_Wieza": "ac1f09fffe014bf8",
    "Krakow_Kocmyzowska_27C": "ac1f09fffe0dd471",
    "Krakow_Wielopole_17_wew": "ac1f09fffe0deddd",
    "Krasniczyn-Anielpol_remiza": "ac1f09fffe051f1e",
    "Krasniczyn-Boncza": "ac1f09fffe051ef4",
    "Krasniczyn-Chelmiec_kopiec": "ac1f09fffe051eea",
    "Krasniczyn-Czajki_remiza": "ac1f09fffe051efc",
    "Krasniczyn-Drewniki_remiza": "ac1f09fffe051efe",
    "Krasniczyn-Olszanka_": "ac1f09fffe051f26",
    "Krasniczyn-Surhow_szkola": "ac1f09fffe051f0c",
    "Krasniczyn-Urzad_Gminy": "ac1f09fffe051f27",
    "Krasniczyn-gmina14_Surhow_KGW": "ac1f09fffe051ef8",
    "Krasniczyn_Brzeziny_ujecie_wodociagowe": "ac1f09fffe051f49",
    "Krasniczyn_Franciszkow_przepompownia": "ac1f09fffe051f4f",
    "Krasniczyn_Lukaszowka_OSP": "ac1f09fffe051f28",
    "Krasniczyn_Majdan_Surhowski_OSP": "ac1f09fffe051f41",
    "Krosno_Odrzanskie_Metalowcow_9A": "ac1f09fffe091d01",
    "Krosno_Odrzanskie_Odwazny_rezerwa": "ac1f09fffe091ccf",
    "KrynicaMorska_Latarnia": "ac1f09fffe091e95",
    "Lasin_Konarskiego_4_AS": "ac1f09fffe091ef3",
    "Leczyce-GodetowoHydrofornia": "ac1f09fffe014bf9",
    "Lubsko_PGKiM_komin": "ac1f09fffe014c03",
    "Meszna_Kosciol": "ac1f09fffe091e7d",
    "Meszna_Prosta_18": "ac1f09fffe091eb3",
    "Meszna_Remiza": "ac1f09fffe051efa",
    "Olkusz-Konstytucji-Wieza-zegarowa": "ac1f09fffe051f31",
    "OstrowiecSw_Prosta7_Kunow": "ac1f09fffe091ced",
    "OstrowiecSw_Pulanki7": "ac1f09fffe091f0b",
    "Ostrowiec_Swietokrzyski_Os_Pulanki_45": "ac1f09fffe0cd46c",
    "OzarowMazowiecki_Jozefow_Fabryczna3a": "ac1f09fffe05906c",
    "Ozarow_Mazowiecki_Jozoefow_Fabryczna_15": "ac1f09fffe091e5f",
    "Ozarow_Mazowiecki_Kolejowa_4_wew": "ac1f09fffe091cf3",
    "Ozarow_Mazowiecki_Mickiewicza_49_wew": "ac1f09fffe091cc5",
    "Ozarow_Mazowiecki_Poznanska_167": "ac1f09fffe091ec1",
    "Ozarow_Mazowiecki_Poznanska_200_wew": "ac1f09fffe091cbf",
    "Ozarow_Mazowiecki_Poznanska_354": "ac1f09fffe091ed9",
    "Ozarow_Stodolna1_UrzadGminy": "ac1f09fffe091ecb",
    "Ozarowice_Celiny_OSP": "ac1f09fffe091f05",
    "Ozarowice_Centralna5": "ac1f09fffe091ec9",
    "Ozarowice_Jogodowa_Topolowa_Studnia": "ac1f09fffe091e85",
    "Ozarowice_Niezdara": "ac1f09fffe091f03",
    "Ozarowice_Oparowa_studnia": "ac1f09fffe091e73",
    "Ozarowice_Ossy_nowa": "ac1f09fffe091efd",
    "Ozarowice_Podlaczna": "ac1f09fffe091e67",
    "Ozarowice_Pyrzowice_SP": "ac1f09fffe091ef7",
    "Ozarowice_Strakow": "ac1f09fffe091eab",
    "Ozarowice_Szkolna10": "ac1f09fffe091e7f",
    "Ozarowice_Tapkowice_Szkola_nowa": "ac1f09fffe091e9d",
    "Ozarowice_Tarnogorska55c_studnia": "ac1f09fffe091ee1",
    "Ozarowice_Topolowa_8": "ac1f09fffe091e8b",
    "Ozarowice_Urzad_Gminy": "ac1f09fffe091f09",
    "Ozarowice_Zendek_Glowna2_studnia": "ac1f09fffe091edb",
    "Ozarowice_Zendek_Klub": "ac1f09fffe091e71",
    "Ozarowice_Zendek_OSP": "ac1f09fffe091f01",
    "PDI_Rezerwa_wew": "ac1f09fffe091cdd",
    "PROGRAMISTA_Rezerwa_Fila_wew_37": "ac1f09fffe0dda9b",
    "Pelplin-Starogardzka12": "ac1f09fffe051f0a",
    "Poznan_Stablewskiego6_FILA": "ac1f09fffe0cd48e",
    "Pultusk_Mickiewicza": "ac1f09fffe014c07",
    "Pyskowice-BraciPisko2-6": "ac1f09fffe059070",
    "Raciborz_DOM_EK_Polna6": "ac1f09fffe091ed7",
    "Raciborz_KOLEJARZ_Chelmonskiego30": "ac1f09fffe014bec",
    "Raciborz_wew_Drzymaly20": "ac1f09fffe0ddb29",
    "Radlin_Irysowa3B": "ac1f09fffe0cd474",
    "Radlin_Sienkiewicza12": "ac1f09fffe091e81",
    "Radlin_WodzislawSlaski_Wawrzynca5": "ac1f09fffe091eb1",
    "Radom_Komandosow2": "ac1f09fffe051f3c",
    "Radom_Osiedlowa22": "ac1f09fffe051eec",
    "Radom_Sandomierska16": "ac1f09fffe051f3d",
    "Radom_Swietokrzyska15": "ac1f09fffe091ec3",
    "Rezerwa_Fila_wew_74_TESTY_BiR": "ac1f09fffe109e73",
    "SZTUM_RAK_KOMIN": "ac1f09fffe004f2b",
    "Skarszewy_Mlynska": "ac1f09fffe014c04",
    "Slupsk_Baltycka2": "ac1f09fffe10aa8d",
    "Sosnowiec_Zaglebiowska19": "ac1f09fffe091cb5",
    "Swierklaniec-NoweChechloSZKOLA": "ac1f09fffe051f39",
    "Swierklaniec-Orzech-SP": "ac1f09fffe051f32",
    "Swierklaniec_Gorna_33_AS_Nowa": "ac1f09fffe0cd4de",
    "Swierklaniec_Kosciuszki13": "ac1f09fffe091e55",
    "Swierklaniec_oczyszczalnia_sciekow_wew": "ac1f09fffe10a887",
    "Tarnowskie_Gory_Poswtancow_Slaskich22": "ac1f09fffe091cf5",
    "Tychy_Niepodleglosci26": "ac1f09fffe091ce7",
    "Walim_WzgorzeWawel2_FILA": "ac1f09fffe0dd429",
    "Warszawa-Bewit_Ciolka26": "ac1f09fffe014c02",
    "Warszawa-Bewit_Obozowa75": "ac1f09fffe053a2c",
    "Warszawa_Administrator_Brzeska18": "ac1f09fffe05906e",
    "Warszawa_Administrator_Gornoslaska7A_FILA": "ac1f09fffe091ceb",
    "Warszawa_Bruzdowa125_FILA": "ac1f09fffe091ea7",
    "Warszawa_GGKO_alJerozolimskie83A": "ac1f09fffe091cd9",
    "Warszawa_Grabow_Lalewicza17": "ac1f09fffe091cc3",
    "Warszawa_Grabow_Pulawska300A": "ac1f09fffe091e83",
    "Warszawa_Grabow_Pulawska320": "ac1f09fffe091eaf",
    "Warszawa_Monolit_Deotymy_19": "ac1f09fffe051f46",
    "Warszawa_Politechnika_Belska6": "ac1f09fffe091efb",
    "Warszawa_Ursus_Wojciechowskiego36A_AS": "ac1f09fffe091ead",
    "Warszawa_Victus_Drewnickiego20_Nowa": "ac1f09fffe091cf9",
    "Wegrow_Gdanska69": "ac1f09fffe091e8f",
    "Wegrow_Zeromskiego3A": "ac1f09fffe091ed5",
    "Wilkowice_Straz_Pozarna": "ac1f09fffe091eb5",
    "Witowo_Zbiornik_ujecia_wody": "ac1f09fffe091ea1",
    "Wloszakowice_Boguszyn_Zbiornik_Wodny_FILA": "ac1f09fffe091f0d",
    "Wloszakowice_Grotniki_Piaskowa3_OSP": "ac1f09fffe091edf",
    "Wloszakowice_Grotniki_Sloneczna7": "ac1f09fffe091ef1",
    "Wloszakowice_JezierzycaKoscielna_Szkolna25_FILA": "ac1f09fffe091e97",
    "Wloszakowice_JezierzyceKoscielne_Rolna_Piekarnia_FILA": "ac1f09fffe091ec7",
    "Wroclaw_Bonitatis_Bielany_Ogrodowa19D": "ac1f09fffe0ddb2d",
    "Wroclaw_Bonitatis_Bielany_Ogrodowa19E": "ac1f09fffe0dec3f",
    "Wroclaw_Oltaszynska74_wew": "ac1f09fffe0de6f9",
    "Ziebice_Walowa": "ac1f09fffe091edd",
    "Ziebice_Widokowa": "ac1f09fffe091e5b",
    "Ziebice_kerlink": "7276ff000b0312b0",
    "ZielonaGora_Gubin_Sportowa15_FILA": "ac1f09fffe051f24",
    "ZielonaGora_Kreta47A_FILA": "ac1f09fffe051f4c",
    "ZielonaGora_Lezyca_Inzynierska5_FILA": "ac1f09fffe051f3f",
    "ZielonaGora_Sulechow_Kosciuszki19_FILA": "ac1f09fffe051f17",
    "ZielonaGora_Szprotawa_Koszarowa11_FILA": "ac1f09fffe014bfe",
    "Zielona_Gora_Zary_Witosa10_FILA": "ac1f09fffe0dec43",
    "testy_dr_inz_Marcin_Klimczak": "ac1f09fffe10a6ed",
    "testy_dr_inz_Tomasz_Cholewa_Lublin": "ac1f09fffe091cd3",
    "x_AGBG_Projekt_wew": "ac1f09fffe091cbb",
    "x_BrzegDolny_Wolow_WojskaPolskiego11_zdemontowana": "ac1f09fffe091ce1",
    "x_Bytom-Falata30_Zdemontowana_karta_zabrana": "ac1f09fffe051f4b",
    "x_Darlowo_Kosciol_zdemontowana": "7276ff000b031383",
    "x_Gdansk_HOMESYSTEM_Obroncow_Wybrzeza15": "ac1f09fffe051f42",
    "x_Krasniczyn-gmina19": "ac1f09fffe051f2b",
    "x_Lubianka": "ac1f09fffe014bfa",
    "x_Ozarow_Mazowiecki_Poznanska_167_zdemontowana": "ac1f09fffe091ee3",
    "x_Radom_Swietokrzyska15_firma": "ac1f09fffe004f29",
    "x_Reklamacja_Ziebice-Paczkowska": "ac1f09fffe051f22",
    "x_Rezerwa_Fila_0_Wylaczona_Firma": "ac1f09fffe051f3b",
    "x_Rezerwa_Fila_1": "ac1f09fffe004f2a",
    "x_Rezerwa_Fila_20_Firma": "ac1f09fffe091ebb",
    "x_Rezerwa_Gdansk_Poludnie1": "ac1f09fffe051f25",
    "x_Rezerwa_Gryfino_SMDO2": "ac1f09fffe014bfd",
    "x_SOLAR_TARAS_FILA": "ac1f09fffe091ebd",
    "x_Swierklaniec_Kosciuszki13_firma": "ac1f09fffe051f2e",
    "x_Ziebice1-Walowa": "7276ff000b03128e",
    "x_Ziebice_Walowa_Zdemontowana": "ac1f09fffe013d7e",
    "x_Ziebice_Widokowa": "ac1f09fffe013d82",
    "x_Zwrot_Pultusk": "ac1f09fffe014bf7",
    "x_Zwrot_Swierklaniec_Gorna33": "ac1f09fffe051f3a",
    "x_darlowo_reklamacja": "ac1f09fffe051f2d",
    "x_wiran_RAK7268V2": "ac1f09fffe092789"
}

export const gwIdToName = Object.fromEntries(Object.entries(gwNameToId).map(a => [a[1], a[0]]));
